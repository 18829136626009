@import "theme-factory";

$primary-color: (
  usual-color: #1F2532,
  lighter-color: #b3e3e7,
  darker-color: #008694,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);
$accent-color: (
  usual-color: #f46519,
  lighter-color: #fcd1ba,
  darker-color: #721c24,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);
$warn-color: (
  usual-color: #ff0000,
  lighter-color: #ffb3b3,
  darker-color: #721c24,
  is-text-color-light: true,
  is-lighter-color-light: false,
  is-darker-color-light: true,
);

// Theme Init
.bondi-blue-theme {
  @include custom-mat-theme($primary-color, $accent-color, $warn-color);
}

.bondi-blue-theme.light-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1C2C3F;
  --mdc-filled-button-label-text-color: #fff;
}